// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const TARGET_URL = 'https://tenders360-api-dev.tenders360.com';
const API_URL = '/api';
const PORT = 4200;
const REDIRECTION_URL = 'http://localhost:4200';
export const environment = {
  production: false,
  ENDPOINTS: {
    BASE_URL: `http://localhost:${PORT}`,

    LOGIN_URL: `${TARGET_URL}${API_URL}/authenticate`,
    OFFERS_URL: `${TARGET_URL}${API_URL}/consultedMarchees`,
    SEARCH_OFFERS_URL: `${TARGET_URL}${API_URL}/consultedMarchees`,
    ALL_USER_FAVORITE_URL: `${TARGET_URL}${API_URL}/favorises`,
    PUBLIC_OFFERS_URL: `${TARGET_URL}/public${API_URL}/marchees`,
    MARCHEE_URL: `${TARGET_URL}${API_URL}/marchees`,
    IMAGES_URL: `${TARGET_URL}${API_URL}/images`,
    REGISTER_URL: `${TARGET_URL}${API_URL}/register`,
    FORGOT_PASSWORD_URL: `${TARGET_URL}${API_URL}/account/reset-password/init`,
    RESET_PASSWORD_URL: `${TARGET_URL}${API_URL}/account/reset-password/finish`,
    USER_INFO_URL: `${TARGET_URL}${API_URL}/account`,
    CHANGE_PASSWORD_URL: `${TARGET_URL}${API_URL}/account/change-password`,
    ACCOUNT_ACTIVATION: `${TARGET_URL}${API_URL}/activate`,
    JOB_CLICK: `${TARGET_URL}${API_URL}/click-on`,
    CONSULTED_OFFERS: `${TARGET_URL}${API_URL}/consultations`,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full-layout/full-layout.component";
import { CommonLayoutComponent } from "./layouts/common-layout/common-layout.component";
import { authGuard } from './core/auth/guards/auth.guard';

const appRoutes: Routes = [
  { path: '',
   redirectTo: 'home',
    pathMatch: 'full'
  },
  {
      path: '',
      redirectTo: '/authentication/sign-in',
      pathMatch: 'full',
  },
  {
      path: '',
      component: CommonLayoutComponent,
      children: [
        {
          path: 'home',
          loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        },
        {
          path: 'favorites',
          loadChildren: () => import('./favorite/favorite.module').then(m => m.FavoriteModule),

          canMatch: [authGuard],
        },
        {
          path: 'recap',
          loadChildren: () => import('./recap/recap.module').then(m => m.RecapModule),
          canMatch: [authGuard],
        },
        {
          path: 'profile',
          loadChildren: () => import('./profile/profile.routes'),
          canMatch: [authGuard],
        },
      ]
  },
  {
      path: '',
      component: FullLayoutComponent,
      children: [
        {
          path: 'authentication',
          loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      }
      ]
  }
];
@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadAllModules,
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled'
        })
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}


<form nz-form class="login-form" nzLayout="vertical">

  <nz-form-item class="block">
    <!-- <nz-form-label nzRequired nzFor="search"
      class="text-[14px] w-full leading-[1.4285714286] font-medium [&>label]:text-dark [&>label]:dark:text-white/[.87] mb-[8px] capitalize inline-block">
      Recherche par mot clé ou ANEP</nz-form-label> -->
    <nz-form-control>
      <input type="text" nz-input [formControl]="fieldKeyControl" placeholder="Recherche par mot clé ou ANEP" id="search"
      (ngModelChange)="searchKeyChanged(fieldKeyControl.value,fieldOrgControl.value)"
        class="flex items-center shadow-none py-[10px] px-[20px] h-[48px] border-1 border-regular dark:border-white/10 rounded-4 w-full text-[14px] font-normal leading-[1.5] placeholder:text-[#A0A0A0] dark:bg-white/10 dark:text-white/60">
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <!-- <nz-form-label nzRequired nzFor="organisme"
      class=" text-[14px] leading-[1.4285714286] font-medium [&>label]:text-dark [&>label]:dark:text-white/[.87] capitalize inline-block">
      Recherche par organisme</nz-form-label> -->
    <nz-form-control>
      <input type="text" nz-input [formControl]="fieldOrgControl" placeholder="Recherche par organisme" id="organisme"
      (ngModelChange)="searchKeyChanged(fieldKeyControl.value,fieldOrgControl.value)"
        class="flex items-center shadow-none py-[10px] px-[20px] h-[48px] border-1 border-regular dark:border-white/10 rounded-4 w-full text-[14px] font-normal leading-[1.5] placeholder:text-[#A0A0A0] dark:bg-white/10 dark:text-white/60">
    </nz-form-control>
  </nz-form-item>


  <nz-form-item>
    <nz-form-label class="flex items-center font-medium dark:text-white/60 mb-[10px]" nzXs="24"
      nzFor="textarea2">Selectionner une wilaya
    </nz-form-label>
    <nz-form-control nzXs="24">
      <nz-select class="basic-select" name="basic-tag-mode" nzMode="multiple" nzPlaceHolder="Selectionner une wilaya"
        [(ngModel)]="listWilayaOfTagOptions" [formControl]="fieldWilayaControl" (ngModelChange)="selectedWilayaChanged()">
        <nz-option *ngFor="let option of listWilayaOfOption" [nzLabel]="option.name" [nzValue]="option.id">
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label class="flex items-center font-medium dark:text-white/60 mb-[10px]" nzXs="24"
      nzFor="textarea2">Selectionner un secteur
    </nz-form-label>
    <nz-form-control nzXs="24">
      <nz-select class="basic-select" name="basic-tag-mode" nzMode="multiple" nzPlaceHolder="Rechercher un secteur"
        [(ngModel)]="listOfTagOptions" [formControl]="fieldControl" (ngModelChange)="selectedSectorsChanged()">
        <nz-option *ngFor="let option of listOfOption" [nzLabel]="option.item" [nzValue]="option.id">
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>


  <nz-form-item>
    <nz-form-label class="text-[15px] font-semibold text-dark dark:text-white/[.87] capitalize mb-[10px]">Date publication</nz-form-label>
    <nz-form-control>
      <nz-date-picker
      class="w-full h-[50px] border-normal dark:border-white/10 px-[20px] placeholder-shown:text-light-extra dark:placeholder-shown:text-white/60 rounded-[5px] dark:bg-white/10"
        nzFormat="yyyy-MM-dd"
        [(ngModel)]="datePub"
        [nzAllowClear]="true"
        [ngModelOptions]="{standalone: true}"
        (ngModelChange)="datePubChanged()"
        nzPlaceHolder="Date publication"
      ></nz-date-picker>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label class="text-[15px] font-semibold text-dark dark:text-white/[.87] capitalize mb-[10px]">Date écheance</nz-form-label>
    <nz-form-control>
      <nz-date-picker
      class="w-full h-[50px] border-normal dark:border-white/10 px-[20px] placeholder-shown:text-light-extra dark:placeholder-shown:text-white/60 rounded-[5px] dark:bg-white/10"
        nzFormat="yyyy-MM-dd"
        [(ngModel)]="dateEch"
        [ngModelOptions]="{standalone: true}"
        (ngModelChange)="dateEchChanged()"
        nzPlaceHolder="Date écheance"
      ></nz-date-picker>
    </nz-form-control>
  </nz-form-item>



</form>


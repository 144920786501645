import { Component, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent {
  password = '';
  title: any = '';
  description: any = '';
  subTitle: any = '';
  subTitleDescription: any = '';
  cancel: any = '';
  action: any = '';
  alertType: any = '';
  color: any = '';
  function: any;
  showCancelButton = true;
  showValidationButton = true;
  needPassword = false;
  dismissible = false;
  passwordVerifcation = false;
  isPasswordFieldInValid = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,

  ) {
    this.title = this.data.title;
    this.description = this.data.description;
    this.subTitle = this.data.subTitle;
    this.subTitleDescription = this.data.subTitleDescription;
    this.cancel = this.data.cancel;
    this.action = this.data.action;
    this.alertType = this.data.alertType;
    this.color = this.data.color;
    this.function = this.data.function;
    this.needPassword = this.data.needPassword ?? false;
    this.showCancelButton = this.data.showCancelButton ?? true;
    this.showValidationButton = this.data.showValidationButton ?? true;
    this.dismissible = this.data.dismissible ?? false;
    this.dismissible = this.data.dismissible ?? false;
  }

  ngOnInit(): void {
  }

  onButtonClick(): void {

  }

  isValid = (): boolean => this.isPasswordFieldInValid;


}

<div class="no-jobs">
  <!-- <img [src]="imageSrc ?? 'assets/images/not-found/not-found.jpg'" alt=""> -->
  <div class="flex flex-col gap-3">
    <span class="text-lg md:text-2xl lg:text-3xl text-slate-900">
      {{ title ?? ' Aucun appel d\'offre trouvé ' }}
    </span>
    <button mat-flat-button color="primary" (click)="onPressed()" class="text-white">
      {{ buttonTitle ?? ' Réessayer' }}</button>
  </div>
</div>

<footer class="px-[25px] bg-primary dark:bg-[#1b1d2a]">
  <div class="flex justify-between flex-wrap py-[22px] gap-x-[30px] gap-y-[15px] items-center max-md:flex-col">
      <div class="flex items-center gap-[4px] text-[14px] font-medium max-md:text-center text-white dark:text-white/60">© Tenders-360  {{ currentYear }} <a class="text-primary" href="#"> All rights reserved</a></div>
      <div class="justify-end md:justify-center items-center flex gap-[15px]">
          <a href="https://www.tenders-360.com/conditionsdutilisation"target="_blank" class="text-white dark:text-white/60 text-[14px] hover:text-primary dark:hover:text-white/[.87]">Conditions d'utilisation</a>
          <a href="https://www.tenders-360.com/conditionsdutilisation"target="_blank" class="text-white dark:text-white/60 text-[14px] hover:text-primary dark:hover:text-white/[.87]">Qui nous sommes</a>
          <a href="https://www.tenders-360.com/conditionsdutilisation"target="_blank" class="text-white dark:text-white/60 text-[14px] hover:text-primary dark:hover:text-white/[.87]">Contactez-nous</a>
      </div>
  </div>
</footer>

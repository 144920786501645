import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { RouterModule } from "@angular/router";
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ThemeConstantService } from './services/theme-constant.service';
import { SearchPipe } from './pipes/search.pipe';
import { OffreCardComponent } from './components/job-card/offre-card.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PopupComponent } from './components/popup/popup.component';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FiltersComponent } from './components/filters/filters.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { CustomizedSnackbarComponent } from './components/customized-snackbar/customized-snackbar.component';
import { MatIconModule } from '@angular/material/icon';
import { NoDataComponent } from './components/no-data/no-data.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';

const antdModule= [
  NzFormModule,
  NzInputModule,
  NzButtonModule,
  NzCardModule,
  NzCheckboxModule,
  NzSelectModule,
  AngularSvgIconModule.forRoot(),
  NzCalendarModule,
  NzDatePickerModule,

]

@NgModule({
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        NzIconModule,
        SearchPipe,
        OffreCardComponent,
        FiltersComponent,
        NoDataComponent,
        MatSnackBarModule

    ],
    imports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        NzIconModule,
        NzToolTipModule,
        MatDialogModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatSnackBarModule,
        ...antdModule
    ],
    declarations: [
        SearchPipe,
        OffreCardComponent,
        PopupComponent,
        FiltersComponent,
        CustomizedSnackbarComponent,
        NoDataComponent
    ],
    providers: [
        ThemeConstantService
    ]
})

export class SharedModule { }

<perfect-scrollbar class="fixed custom-scrollbar side-nav top-[72px] border-r border-[#edf2f9] dark:border-transparent h-full z-[1000] transition-all duration-200 ease-in-out bg-white dark:bg-[#1b1d2a]" sideNav>
  <ul class="bg-white border-none dark:bg-[#1b1d2a] ant-menu ant-menu-root ant-menu-inline side-nav-menu"
      [ngClass]="{
          'ant-menu-inline-collapsed': isFolded,
          'ant-menu-dark' : isSideNavDark
      }"
  >
      <li [ngClass]="item.submenu.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item'"
          *ngFor="let item of menuItems"
          [routerLinkActive]="item.submenu.length > 0 ? 'ant-menu-submenu-open' : 'ant-menu-item-selected'"
          [routerLinkActiveOptions]="{exact: true}">
          <a class="ant-menu-submenu-title" *ngIf="item.submenu.length > 0">
              <i *ngIf="item.iconType == 'nzIcon'" nz-icon [nzType]="item.icon" [theme]="item.iconTheme"></i>
              <i *ngIf="item.iconType == 'fontawesome'" class="me-[10px]" [ngClass]="[item.iconTheme , item.icon]"></i>
              <span>{{item.title}}</span>
              <i class="ant-menu-submenu-arrow"></i>
          </a>
          <a [routerLink]="item.path" (click)="closeMobileMenu()" *ngIf="item.submenu.length === 0">
              <i *ngIf="item.iconType == 'nzIcon'" nz-icon [nzType]="item.icon" [theme]="item.iconTheme"></i>
              <i *ngIf="item.iconType == 'fontawesome'" class="me-[10px]" [ngClass]="[item.iconTheme , item.icon]"></i>
              <span>{{item.title}}</span>
          </a>
          <!-- Second Level -->
          <ul class="ant-menu ant-menu-inline ant-menu-sub dropdown-menu" *ngIf="item.submenu.length > 0">
              <li [ngClass]="subItem.submenu.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item'"
                  *ngFor="let subItem of item.submenu"
                  [routerLinkActive]="subItem.submenu.length > 0 ? 'ant-menu-submenu-open' : 'ant-menu-item-selected'"
                  [routerLinkActiveOptions]="{exact: true}">
                  <a class="ant-menu-submenu-title" *ngIf="subItem.submenu.length > 0">
                      <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon" [theme]="subItem.iconTheme"></i>
                      <i *ngIf="subItem.iconType == 'fontawesome'" class="me-[10px]" [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
                      <span>{{subItem.title}}</span>
                      <i class="ant-menu-submenu-arrow"></i>
                  </a>
                  <a [routerLink]="subItem.path" (click)="closeMobileMenu()" *ngIf="subItem.submenu.length === 0">
                      <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon" [theme]="subItem.iconTheme"></i>
                      <i *ngIf="subItem.iconType == 'fontawesome'" class="me-[10px]" [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
                      <span>{{subItem.title}}</span>
                  </a>

                  <!-- Third Level -->
                  <ul class="ant-menu ant-menu-inline ant-menu-sub dropdown-menu" *ngIf="subItem.submenu.length > 0">
                      <li [ngClass]="subItem.submenu.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item'"
                          *ngFor="let subItem of subItem.submenu" routerLinkActive="ant-menu-item-selected"
                          [routerLinkActiveOptions]="{exact: true}">
                          <a class="ant-menu-submenu-title" *ngIf="subItem.submenu.length > 0">
                              <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon" [theme]="subItem.iconTheme"></i>
                              <i *ngIf="subItem.iconType == 'fontawesome'" class="me-[10px]" [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
                              <span>{{subItem.title}}</span>
                              <i class="ant-menu-submenu-arrow"></i>
                          </a>
                          <a [routerLink]="subItem.path" (click)="closeMobileMenu()" *ngIf="subItem.submenu.length === 0">
                              <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon" [theme]="subItem.iconTheme"></i>
                              <i *ngIf="subItem.iconType == 'fontawesome'" class="me-[10px]" [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
                              <span>{{subItem.title}}</span>
                          </a>
                      </li>
                  </ul>
              </li>
          </ul>
      </li>
  </ul>
</perfect-scrollbar>

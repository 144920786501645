<div>
  <h1 mat-dialog-title> {{title}} </h1>

  <div>
    <mat-dialog-content>
      <div class="mb-4">
        <p>
          {{description}}
        </p>

        <div class="flex flex-col justify-center bg-red-50 p-2 py-3 mt-3 border-l-4 border-red-500" >
          <p class="m-0">
           <strong> {{subTitle}} :</strong> {{subTitleDescription | lowercase}}
        </p>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions *ngIf="showCancelButton || showValidationButton" class="justify-end">
      <button *ngIf="showCancelButton" mat-raised-button mat-dialog-close> {{cancel}} </button>
      <button *ngIf="showValidationButton" mat-raised-button [color]=color (click)="function()" cdkFocusInitial>
        {{action}} </button>
    </mat-dialog-actions>

  </div>

</div>

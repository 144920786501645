import { isPlatformServer } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { Subject, takeUntil, throttleTime } from 'rxjs';
import { Router } from '@angular/router';
import { tenderOffre } from '../../../core/models/tender-offre';
import { StorageUtilsService } from 'src/app/core/storage-utils/storage-utils.service';

@Component({
  selector: 'app-offre-card',
  templateUrl: './offre-card.component.html',
  styleUrls: ['./offre-card.component.scss'],
})
export class OffreCardComponent implements OnInit, OnDestroy {
  @Input() fromRecap?: boolean;
  isLogged?: boolean;
  constructor(
    private storageUtilsService: StorageUtilsService,
    private _router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}
  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
  ngOnInit(): void {
    this.isLogged= this.storageUtilsService.getAccessToken() ? true : false;
    this.favoriteJobSubject
      .pipe(takeUntil(this._unsubscribeAll), throttleTime(1000))
      .subscribe(({ offre }) => this.favoriteClickEmitter({ offre }));
      if(this.offre?.id)this.loaded.emit(this.offre.id);

  }

  @Input() offre?: tenderOffre;
  @Output() onJobPressedEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onJobFavoritePressedEmitter: EventEmitter<any> =
    new EventEmitter<any>();
  private favoriteJobSubject = new Subject<{
    offre?: tenderOffre;
    event: MouseEvent;
  }>();

  @Output() loaded = new EventEmitter<number>();

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /* on offre card clicked */
  onJobCardClicked(event: any): void {
    event.stopPropagation();

    if (this.fromRecap) {
    const url = this._router.createUrlTree(['home', 'tenders', this.offre?.id]);
    window.open(url.toString(), '_blank');
    } else {
      this._router.navigate(['home', 'tenders', this.offre?.id]);
    }
  }
  /* on favorite icon clicked */
  favoriteClicked({
    offre,
    event,
  }: {
    offre?: tenderOffre;
    event: MouseEvent;
  }): void {
    event.preventDefault();
    event.stopPropagation();
    this.favoriteJobSubject.next({ offre: offre, event: event });
  }

  favoriteClickEmitter({ offre }: { offre?: tenderOffre }): void {
    this.onJobFavoritePressedEmitter.next({ offre: offre });
  }

  getFavoriteIconClass({ offre }: { offre?: tenderOffre }): string[] {
    if (isPlatformServer(this.platformId)) {
      return ['heart'];
    } else {
      return ['heart', offre?.addedToFavoris ?? false ? 'heart-active' : ''];
    }
  }

  getConsultedIconClass({ offre }: { offre?: tenderOffre }): string[] {
    if (isPlatformServer(this.platformId)) {
      return ['material-icons'];
    } else {
      return [
        'material-icons',
        offre?.consulted ?? false ? 'material-icons-outlined' : '',
      ];
    }
  }

  testDateIfBeforeThreeDays(dateEc: any): boolean {
    const now = new Date();
    const date = new Date(dateEc);
    const diff = date.getTime() - now.getTime();
    return diff < 259200000 && diff > 0;
  }

  testIfDateIsExpired(dateEc: any): boolean {
    const now = new Date();
    const date = new Date(dateEc);
    const diff = date.getTime() - now.getTime();
    return diff < 0;
  }
}


<div class="border-b header border-[#edf2f9] dark:border-none bg-white dark:bg-[#323540] min-h-[72px] fixed top-0 w-full z-[1000] flex">
  <div class="border-r border-[#edf2f9] dark:border-transparent logo logo-dark transition duration-200 ease-in-out items-center h-[71px]">
    <a class="block text-center" href="">
      <img id="logo-img" class="ps-[10px]  h-[71px]" src="assets/images/logo/tenders_logo_cropped.png" alt="Logo">
      <img id="logo-fold-img" class="p-[10px] logo-fold  h-[71px]" src="assets/images/logo/tenders_logo_cropped.png" alt="Logo">
    </a>
  </div>
  <div class="flex flex-1 nav-wrap md:ps-[20px] md:pe-[30px] ps-[10px] pe-[15px] bg-white dark:bg-[#1b1d2a]">
    <ul class="flex items-center mb-0 list-none nav-left ps-0 gap-x-[14px] gap-y-[9px]">
    </ul>
    <!-- Top Menu -->
    <app-menu *ngIf="checkLogged()"></app-menu>

    <!-- desktop -->
    <div *ngIf="checkLogged()" class="items-center hidden ms-auto xl:flex">
      <ng-container *ngTemplateOutlet="myTemplate"></ng-container>
    </div>
    <ng-template #myTemplate>
      <ul class="flex items-center justify-end flex-auto mb-0 list-none ps-0 nav-right sm:gap-x-[25px] max-sm:gap-x-[15px] gap-y-[15px]">
        <li>
          <span class="flex items-center cursor-pointer text-light whitespace-nowrap" nz-dropdown [nzDropdownMenu]="profile">
            <nz-avatar [nzIcon]="'user'" nzSrc="assets/icons/profile.png"></nz-avatar>
            <span class="me-1.5 ms-2.5 text-body dark:text-white/60 text-sm font-medium max-md:hidden"></span>
            <!-- <span class="[&>svg]:w-[10px] [&>svg]:h-[10px] [&>svg]:max-md:ms-[5px]" nz-icon nzType="down" nzTheme="outline"></span> -->
          </span>
          <nz-dropdown-menu #profile="nzDropdownMenu">
            <div class="min-w-[310px] max-sm:min-w-full pt-4 px-[15px] py-[12px] bg-white dark:bg-[#1b1e2b] shadow-[0_2px_8px_rgba(0,0,0,.15)] dark:shadow-[0_5px_30px_rgba(1,4,19,.60)] rounded-4">
              <ul nz-menu class="mb-0 bg-transparent shadow-none">
                <li class="p-0 dark:hover:text-white hover:bg-primary/10 dark:hover:bg-white/10 rounded-4" nz-menu-item *ngFor="let authorMenu of appAuthorMenu"(click)="goTo(authorMenu.url)">
                  <button

                    class="inline-flex items-center text-light dark:text-white/60 hover:text-primary hover:ps-6 w-full px-2.5 py-3 text-sm transition-all ease-in-out delay-150">
                      <svg-icon class="h-fit me-4 [&>svg]:w-[18px] [&>svg]:h-[18px]" src="assets/images/svg/feather/{{authorMenu.icon}}.svg" alt="{{authorMenu.icon}}"></svg-icon>
                      {{authorMenu.label}}</button>
                  </li>
              </ul>
              <a
                class="flex items-center justify-center text-sm font-medium bg-[#f4f5f7] dark:bg-[#32333f] h-[50px] text-light hover:text-primary dark:hover:text-white/60 dark:text-white/[.87] mx-[-15px] mb-[-15px] rounded-b-6"
                href="#" (click)="signOut()">
                <i class="facebook"></i> Se déconnecter</a>
            </div>
          </nz-dropdown-menu>
        </li>
      </ul>
    </ng-template>
    <!-- desktop -->
    <!-- Mobile -->
    <a *ngIf="checkLogged()" class="items-center hidden max-xl:flex text-light text-[18px] dark:text-white/[.87] ms-auto" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
      <!-- <span nz-icon nzType="more" nzTheme="outline"></span> -->
      <nz-avatar [nzIcon]="'user'" nzSrc="assets/icons/profile.png"></nz-avatar>
    </a>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <div class="min-w-[310px] max-sm:min-w-full pt-4 px-[15px] py-[12px] bg-white dark:bg-[#1b1e2b] shadow-[0_2px_8px_rgba(0,0,0,.15)] dark:shadow-[0_5px_30px_rgba(1,4,19,.60)] rounded-4">
        <ul nz-menu class="mb-0 bg-transparent shadow-none">
          <li class="p-0 dark:hover:text-white hover:bg-primary/10 dark:hover:bg-white/10 rounded-4" nz-menu-item *ngFor="let authorMenu of appAuthorMenu" (click)="goTo(authorMenu.url)">
            <button

              class="inline-flex items-center text-light dark:text-white/60 hover:text-primary hover:ps-6 w-full px-2.5 py-3 text-sm transition-all ease-in-out delay-150">
                <svg-icon class="h-fit me-4 [&>svg]:w-[18px] [&>svg]:h-[18px]" src="assets/images/svg/feather/{{authorMenu.icon}}.svg" alt="{{authorMenu.icon}}"></svg-icon>
                {{authorMenu.label}}</button>
            </li>
        </ul>
        <a
          class="flex items-center justify-center text-sm font-medium bg-[#f4f5f7] dark:bg-[#32333f] h-[50px] text-light hover:text-primary dark:hover:text-white/60 dark:text-white/[.87] mx-[-15px] mb-[-15px] rounded-b-6"
          href="#" (click)="signOut()">
          <i class="facebook"></i> Se déconnecter</a>
      </div>
    </nz-dropdown-menu>
    <!-- Mobile -->


    <div *ngIf="!checkLogged()"class="flex flex-row items-center  ms-auto gap-1 sm:gap-4">
      <button nz-button
      style="background-color: #89be40"
      [routerLink]="'/authentication/sign-in'"
      class="capitalize border-solid border-1 border-primary text-white dark:text-white/[.87] text-[14px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-[40px] px-[5px] sm:px-[20px] h-[44px] [&>span]:inline-flex">
      Se Connecter
    </button>
      <button nz-button
      [routerLink]="'/authentication/sign-up'"
      class="bg-cyan-500 capitalize border-solid border-1 border-primary text-white dark:text-white/[.87] text-[14px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-[40px] px-[20px] h-[44px] [&>span]:inline-flex">
      S'inscrire
    </button>

    </div>

    <div class="dark-single-switch flex items-center ml-1 sm:ml-4">
      <ul>
         <li>
            <a (click)="toggleDarkMode()" class="relative flex items-center justify-center w-10 h-10 leading-[63px] text-3xl text-white bg-dark transition-[0.5s] rounded-[50%] before:content-[''] before:absolute before:w-full before:h-full before:transition-[0.5s] before:z-[-1] before:rounded-[50%] before:scale-90 before:left-0 before:top-0 dark:bg-white dark:before:bg-white active:before:scale-110 dark:text-dark text-[22px] shadow-md dark:shadow-lg">

               <svg-icon *ngIf="!isDarkMode" class="text-body dark:text-white/60 dark:hidden"
                src="assets/images/svg/feather/moon.svg"></svg-icon>
               <svg-icon *ngIf="isDarkMode" class="text-body dark:flex dark:text-warning"
                src="assets/images/svg/feather/sun.svg"></svg-icon>
            </a>
         </li>
      </ul>
   </div>

  </div>
</div>

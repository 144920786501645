<a
  class="offre-card-item"
  (click)="onJobCardClicked($event)"
  [ngClass]="{ 'offre-card-item-recap': fromRecap }"
>
  <div class="icon-text-container parition-date">
    <span *ngIf="!fromRecap">{{ offre?.dateParution }}</span>
    <span *ngIf="fromRecap">{{ offre?.tendersTypeAttachmentName }}</span>
  </div>
  <!-- header-->
  <div class="card-header">
    <!-- info part -->
    <div class="info-image-container">

      <!-- info with icons part -->
      <div class="infos-container">
        <div class="offre-title">
          <span>{{ offre?.title | titlecase }}</span>
        </div>
        <div class="header-info-line" *ngIf="offre?.organisme?.organismName">
          <!-- <img src="assets/icons/company.png"> -->
          <span *ngIf="!isLogged">Information réservée aux abonnés</span>
          <span *ngIf="isLogged">{{ offre?.organisme.organismName }}</span>
        </div>
        <div class="hiring-organization">
          <div class="header-info-line">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="30"
              height="30"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 100 125"
              enable-background="new 0 0 100 100"
              xml:space="preserve"
            >
              <g>
                <path
                  [ngClass]="{
                    colorWarn: testDateIfBeforeThreeDays(offre?.dateEcheance),
                    colorRed: testIfDateIsExpired(offre?.dateEcheance)
                  }"
                  fill="#1f4a75"
                  d="M55.161,1.781C29.504-0.103,7,20.014,4.893,46.716C2.798,73.4,21.911,96.578,47.593,98.461   c25.645,1.867,48.162-18.241,50.248-44.935C99.948,26.823,80.831,3.666,55.161,1.781z M82.296,69.312L47.738,52.847l-0.22-32.237   l5.852-0.033l0.191,28.603l31.256,14.89L82.296,69.312z"
                />
              </g>
            </svg>
            <!-- <img src="assets/icons/clock.png"> -->
            <span
              *ngIf="isLogged"
              [ngClass]="{
                colorWarn: testDateIfBeforeThreeDays(offre?.dateEcheance),
                colorRed: testIfDateIsExpired(offre?.dateEcheance)
              }"
              >{{ offre?.dateEcheance }}</span
            >
            <span *ngIf="!isLogged">Information réservée aux abonnés</span>
          </div>

          <div class="header-info-line" *ngIf="offre?.wilayaMarchee">
            <img src="assets/icons/location.png" />
            <span>{{ offre?.wilayaMarchee?.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- card middle part -->
  <div class="card-middle-info-part" *ngIf="offre?.secteurDTOS && !fromRecap">
    <div
      class="icon-text-container-secteur"
      *ngFor="let secteur of offre?.secteurDTOS"
    >
      <!-- <img src="assets/icons/agreement.png" alt=""> -->
      <span class="text-slate-600">{{ secteur?.name }}</span>
    </div>
    <div class="icon-text-container" *ngIf="offre?.requiredExperience">
      <img src="assets/icons/medal.png" alt="" />
      <span>{{ offre?.requiredExperience }}</span>
    </div>
    <div class="icon-text-container" *ngIf="(offre?.baseSalary ?? 0) > 0">
      <span>{{ offre?.baseSalary }}</span>
    </div>
  </div>
  <!-- card footer part -->
  <div class="card-footer" *ngIf="!fromRecap">
    <div class="description">
      <span class="text-slate-600">
        {{ offre?.jobDescription }}
      </span>
    </div>
    <div class="icon-text-container">
      <!-- <img src="assets/icons/screen.png" alt=""> -->
      <span class="text-slate-100">{{ offre?.tendersTypeAttachmentName }}</span>
    </div>
  </div>
  <!-- favorite icon part -->
  <!-- <ng-template > -->
    <div *ngIf="!fromRecap && isLogged"
      class="favorite-icon-part h-fit w-fit"
      (click)="favoriteClicked({ offre: offre, event: $event })"
    >
      <div class="min-h-6 min-w-6 p-3 h-fit w-fit relative z-20">
        <span id="heart" [ngClass]="getFavoriteIconClass({ offre })"></span>
      </div>
    </div>
  <!-- </ng-template> -->

  <!-- <ng-template > -->
    <div *ngIf="offre?.consulted && isLogged" class="consulted-icon-part h-fit w-fit">
      <div class="min-h-6 min-w-6 p-3 h-fit w-fit relative z-20 mr-2">
        <span [ngClass]="getConsultedIconClass({ offre })" class="text-green-500">
          check_circle
        </span>
      </div>
    </div>
  <!-- </ng-template> -->
</a>

import {
  TopMenuInterface
} from '../../interfaces/top-menu';

export const ROUTES: TopMenuInterface[] = [
  {
    path: 'home',
    megaClass: '',
    title: 'Accueil',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'appstore-add',
    submenu: []
  },
  {
    path: 'recap',
    megaClass: '',
    title: 'Récapitulatif du jour',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'appstore',
    submenu: []
  },
  {
    path: 'favorites',
    megaClass: '',
    title: 'Mes favoris',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'layout',
    submenu: []
  },
]

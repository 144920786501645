import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Signal,
} from '@angular/core';
import { map, Observable, Subject, Subscription } from 'rxjs';
import {
  Filters,
  FiltersDto,
  FiltersTypes,
  LabelValueModel,
} from '../../../core/models/filters';
import { Constants } from '../../../core/data/constants';
import { FormControl, FormGroup } from '@angular/forms';
import { sectors, wilaya } from 'src/app/core/data/filters-data';
import moment from 'moment';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit, OnDestroy {
  fieldOrgControl = new FormControl('');
  fieldKeyControl = new FormControl('');

  fieldWilayaControl = new FormControl([]);
  listWilayaOfOption: Array<{ id: any; name: any }> = [];
  listWilayaOfTagOptions = [];

  fieldControl = new FormControl([]);
  listOfOption: Array<{ id: any; item: any }> = [];
  listOfTagOptions = [];

  datePub: any = '';
  dateEch: any = '';
  searchTimeOut: any;
  constructor() {}
  private _unsubscribeAll = new Subject<void>();

  private eventsSubscription: Subscription | undefined;

  @Input() events: Observable<void> | undefined;
  @Input() filtersDto: FiltersDto;

  @Output() filtersChangedEvent: EventEmitter<any> = new EventEmitter();
  filters: any[] = [];
  deletFilterSubject: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.eventsSubscription = this.events?.subscribe(() => {
      this.fieldOrgControl = new FormControl('');
      this.fieldKeyControl = new FormControl('');

      this.fieldWilayaControl = new FormControl([]);
      this.listWilayaOfTagOptions = [];

      this.fieldControl = new FormControl([]);
      this.listOfTagOptions = [];
      this.datePub= '';
      this.dateEch = '';
    });

    this.listOfOption = this.sectors;
    this.listWilayaOfOption = this.wilayas;

    this.fieldOrgControl = new FormControl(this.filtersDto?.organismSearch ?? '');
    this.fieldKeyControl = new FormControl(this.filtersDto?.search ?? '');

    this.fieldWilayaControl = new FormControl((this.filtersDto?.wilayaIds?.split(',')??[]).map(Number));
    this.listWilayaOfTagOptions = (this.filtersDto?.wilayaIds?.split(',')??[]).map(Number);

    this.fieldControl = new FormControl((this.filtersDto?.sectorIds?.split(',')??[]).map(Number));
    this.listOfTagOptions = (this.filtersDto?.sectorIds?.split(',')??[]).map(Number);
    this.datePub= this.filtersDto?.equalToDatePublication??'';
    this.dateEch = this.filtersDto?.equalTodateEcheance??'';
  }

  ngOnDestroy() {
    this.eventsSubscription?.unsubscribe();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  public get FiltersTypesEnum() {
    return FiltersTypes;
  }



  /** filters change handler */
  filterChangedHandlerFun({ filter }: { filter: Map<string, any> }): void {
    /** notify children to update */

  }


  get sectors() {
    return sectors;
  }

  get wilayas() {
    return wilaya;
  }


  searchKeyChanged(valueKey: any, valueOrg: any) {
    clearTimeout(this.searchTimeOut);
    this.searchTimeOut = setTimeout(() => {
      const val = { valueKey, valueOrg };
      const search: Map<string, any> = new Map<string, any>().set(
        Constants.SEARCH_FILTER,
        val,
      );
      this.filtersChangedEvent.next(search);
    }, 800);
  }

  selectedWilayaChanged(){
    const val = this.fieldWilayaControl.value?.filter(item => item !== 0);
    console.log(val);

    const wilaya: Map<string, any> = new Map<string, any>().set(
      Constants.WILAYAS_FILTER,
      val,
    );
    console.log(wilaya);

    this.filtersChangedEvent.next(wilaya);

  }
  selectedSectorsChanged(){
    const val = this.fieldControl.value.filter(item => item !== 0);
    const sector: Map<string, any> = new Map<string, any>().set(
      Constants.SECTORS_FILTER,
      val,
    );
    this.filtersChangedEvent.next(sector);

  }
  datePubChanged(){
    const datePub = new Map<string, any>().set(
      Constants.PUB_DATE_FILTER,
      this.datePub?moment(this.datePub).format('YYYY-MM-DD'):''
    );
    this.filtersChangedEvent.emit(datePub);
  }

  dateEchChanged(){
    const dateEch = new Map<string, any>().set(
      Constants.ECH_DATE_FILTER,
      this.dateEch? moment(this.dateEch).format('YYYY-MM-DD'):null

    );
    this.filtersChangedEvent.emit(dateEch);
  }
}

import { Component } from '@angular/core';
import { ThemeConstantService } from '../../services/theme-constant.service';
import messages from '../../../../assets/data/global/header/messages.json';
import notification from '../../../../assets/data/global/header/notification.json';
import authorMenu from '../../../../assets/data/global/header/author-menu.json';
import settings from '../../../../assets/data/global/header/settings.json';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/service/auth.service';
import { map } from 'rxjs';
import { StorageUtilsService } from 'src/app/core/storage-utils/storage-utils.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  searchVisible: boolean = false;
  quickViewVisible: boolean = false;
  isFolded: boolean;
  isExpand: boolean;
  appMessages = messages.appMessages;
  appNotification = notification.appNotification;
  appAuthorMenu = authorMenu.appAuthorMenu;
  appSettings = settings.appSettings;

  isLoged: boolean;
  isDarkMode: boolean;

  constructor(
    private themeService: ThemeConstantService,
    private _router: Router,
    private authService: AuthService,
    private _StorageUtilsService: StorageUtilsService
  ) {
    this.isDarkMode = JSON.parse(localStorage.getItem('isDarkMode') || 'false');
  }

  signOut(): void {
    this.authService.signOut();
  }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );
    this.authService.checkIsLoggedIn().pipe(
      map((isLogged: boolean | null) => {
        isLogged ? (this.isLoged = true) : (this.isLoged = false);
      })
    );
    const isDarkModeEnabled = localStorage.getItem('darkModeEnabled');
    if (isDarkModeEnabled === 'true') {
      const body = document.querySelector('body');
      body.classList.add('dark');
      const logoImg = document.getElementById('logo-img') as HTMLImageElement;
      const logoFoldImg = document.getElementById(
        'logo-fold-img'
      ) as HTMLImageElement;
      logoImg.src = 'assets/images/logo/tenders_logo_cropped.png';
      logoFoldImg.src = 'assets/images/logo/logo-fold.png';
    }
  }

  toggleFold() {
    this.isFolded = !this.isFolded;
    this.themeService.toggleFold(this.isFolded);
  }

  toggleExpand() {
    this.isFolded = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isFolded);
  }

  searchToggle(): void {
    this.searchVisible = !this.searchVisible;
  }

  quickViewToggle(): void {
    this.quickViewVisible = !this.quickViewVisible;
  }
  goTo(route: string): void {
    this._router.navigate([route]);
  }

  checkLogged(): boolean {
    if (this._StorageUtilsService.getAccessToken()) {
      return true;
    }
    return false;
  }

  toggleDarkMode() {
    const body = document.querySelector('body');
    body.classList.toggle('dark');

    const isDarkModeEnabled = body.classList.contains('dark');
    localStorage.setItem('darkModeEnabled', isDarkModeEnabled.toString());

    const logoImg = document.getElementById('logo-img') as HTMLImageElement;
    const logoFoldImg = document.getElementById(
      'logo-fold-img'
    ) as HTMLImageElement;

    if (isDarkModeEnabled) {
      logoImg.src = 'assets/images/logo/tenders360logoblanc.png';
      logoFoldImg.src = 'assets/images/logo/logo-fold.png';
    } else {
      logoImg.src = 'assets/images/logo/tenders_logo_cropped.png';
      logoFoldImg.src = 'assets/images/logo/logo-fold.png';
    }

    this.isDarkMode = isDarkModeEnabled;
    localStorage.setItem('isDarkMode', JSON.stringify(this.isDarkMode));
  }
}
